import React, { useState } from 'react';
import './DownloadLink.css';

const DownloadLink = ({ url, filename, label, disabled }) => {
  const [error, setError] = useState(null);

  if (!url) return null;

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Download failed');
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error('Download error:', err);
      setError('Failed to download file. Please try again.');
    }
  };

  return (
    <div>
      <button 
        onClick={handleDownload}
        className={`process-button download-link ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
      >
        {label || 'Download Highlights'}
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default DownloadLink;