import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase-config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './FileUpload.css';
import FileSelector from '../FileSelector';
import HighlightOptions from '../HighlightOptions';
import DownloadLink from '../DownloadLink';
import UserNav from '../UserNav';
import { MAX_FREE_SIZE, MAX_PREMIUM_SIZE } from '../../utils/stripe';
import { useAuth } from '../../context/AuthContext';
import { kebabCase } from 'lodash';
import { getAudioDuration } from '../../utils/audioUtils';
import ProgressDisplay from '../ProgressDisplay/ProgressDisplay';
import MessageDisplay from '../MessageDisplay';

function FileUpload() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [uniqueFileName, setUniqueFileName] = useState('');
  const [downloadUrls, setDownloadUrls] = useState({});
  const [uploading, setUploading] = useState(false);
  const [generatingHighlights, setGeneratingHighlights] = useState(false);
  const [selectedMood, setSelectedMood] = useState('Cinematic');
  const [selectedDuration, setSelectedDuration] = useState('30');
  const [needsUpgrade, setNeedsUpgrade] = useState(false);
  const { user, loading } = useAuth();
  const [jobId, setJobId] = useState(null);
  const [lastUploadedFile, setLastUploadedFile] = useState(null);
  const [isGeneratingNewHighlights, setIsGeneratingNewHighlights] = useState(false);
  const [error, setError] = useState(null);
  const [cachedTranscription, setCachedTranscription] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const MAX_POLLING_TIME = 90000; // 90 seconds in milliseconds
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [processingStage, setProcessingStage] = useState('');
  const [mediaType, setMediaType] = useState(null);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    let intervalId;
    let startTime;

    const pollJobProgress = async () => {
      const currentTime = Date.now();
      if (!isPolling || !jobId || currentTime - startTime > MAX_POLLING_TIME) {
        clearInterval(intervalId);
        setIsPolling(false);
        if (currentTime - startTime > MAX_POLLING_TIME) {
          setError("Operation timed out. Please try again.");
        }
        return;
      }

      try {
        const response = await axios.get(`https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/getJobProgress?jobId=${jobId}`);
        const { progress, stage } = response.data;
        setProcessingProgress(progress);
        if (stage && stage !== 'Processing') {
          setProcessingStage(stage);
        }

        if (progress >= 100) {
          clearInterval(intervalId);
          setIsPolling(false);
        }
      } catch (error) {
        console.error('Error fetching job progress:', error);
        clearInterval(intervalId);
        setIsPolling(false);
        setJobId(null);
      }
    };

    if (isPolling && jobId) {
      startTime = Date.now();
      intervalId = setInterval(pollJobProgress, 2000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isPolling, jobId]);

  const determineMediaType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (['mp4', 'mov', 'avi', 'wmv', 'flv', 'webm'].includes(extension)) {
      return 'video';
    } else if (['mp3', 'wav', 'ogg', 'aac', 'm4a', 'flac'].includes(extension)) {
      return 'audio';
    } else {
      throw new Error('Unsupported file type');
    }
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      console.log('New file selected:', selectedFile.name);
      setError(null);
      const maxSize = user?.isPremium ? MAX_PREMIUM_SIZE : MAX_FREE_SIZE;

      try {
        const detectedMediaType = determineMediaType(selectedFile.name);
        setMediaType(detectedMediaType);

        if (selectedFile.size > MAX_PREMIUM_SIZE) {
          setMessage(`File is too large. Maximum size is ${MAX_PREMIUM_SIZE / (1024 * 1024)}MB.`);
          setFile(null);
          setNeedsUpgrade(false);
        } else if (selectedFile.size > maxSize) {
          setFile(selectedFile);
          setMessage('This file size requires a paid account. Please upgrade to continue.');
          setNeedsUpgrade(true);
        } else {
          setFile(selectedFile);
          setLastUploadedFile(null);
          setCachedTranscription(null);
          setMessage('');
          setUploading(false);
          setGeneratingHighlights(false);
          setDownloadUrls({});
          setNeedsUpgrade(false);
        }
      } catch (error) {
        setError(error.message);
        setFile(null);
      }
    }
  };

  const handleMoodChange = (event) => {
    setSelectedMood(event.target.value);
  };

  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
  };

  const handleUpload = async () => {
    if (!file && !lastUploadedFile) {
      setMessage('Please select a file first.');
      return;
    }

    setError(null);
    setShowMessage(false); // Hide the message when processing starts

    const fileToProcess = file || lastUploadedFile;
    
    try {
      const duration = await getAudioDuration(fileToProcess);
      if (duration < parseInt(selectedDuration, 10)) {
        setError(`The file duration (${Math.round(duration)}s) is shorter than the specified highlight duration (${selectedDuration}s). Please choose a shorter duration or upload a longer file.`);
        return;
      }

      // Add more pre-checks here if needed
      // For example, check file type, size limits, etc.

    } catch (error) {
      console.error('Error during pre-check:', error);
      setError('Failed to analyze the file. Please try again.');
      return;
    }

    console.log('Processing file:', fileToProcess.name);
    setIsGeneratingNewHighlights(true);
    setIsPolling(true);

    if (file && !lastUploadedFile) {
      // Only upload if it's a new file
      setCachedTranscription(null);

      if (file.size > MAX_PREMIUM_SIZE) {
        setMessage(`File is too large. Maximum size is ${MAX_PREMIUM_SIZE / (1024 * 1024)}MB.`);
        return;
      }

      if (file.size > MAX_FREE_SIZE && !user?.isPremium) {
        setMessage('Please upgrade your account to upload files larger than 75MB.');
        setNeedsUpgrade(true);
        return;
      }

      console.log(`Generating highlights for new file`);

      setUploading(true);

      const generatedUniqueFileName = generateUniqueFileName(file.name);
      setUniqueFileName(generatedUniqueFileName);
      console.log('Generated unique file name:', generatedUniqueFileName);

      const storageRef = ref(storage, `uploads/${mediaType}/${generatedUniqueFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setUploadProgress(progress);
          setProcessingStage('uploading');
        },
        (error) => {
          console.error('Upload error:', error);
          setMessage(`Failed to upload file: ${error.message}`);
          setUploading(false);
          setIsPolling(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUploading(false);
            setLastUploadedFile({
              url: downloadURL,
              uniqueFileName: generatedUniqueFileName,
              originalFileName: file.name
            });
            processMedia(downloadURL, generatedUniqueFileName, file.name);
          });
        }
      );
    } else if (lastUploadedFile) {
      console.log(`Generating highlights for last uploaded file`);
      processMedia(lastUploadedFile.url, lastUploadedFile.uniqueFileName, lastUploadedFile.originalFileName);
    }
  };

  const processMedia = async (mediaUrl, uniqueFileName, originalFileName) => {
    setError(null);
    setShowMessage(false); // Ensure message is hidden when processing starts
    console.log(`Processing media for highlights`);
    console.log(`Mood: ${selectedMood}, Duration: ${selectedDuration}`);
    console.log('Unique file name:', uniqueFileName);
    console.log('Original file name:', originalFileName);

    setGeneratingHighlights(true);

    try {
      const jobId = Date.now().toString();
      setJobId(jobId);

      let transcription, mediaInfo;
      if (cachedTranscription) {
        console.log('Using cached transcription');
        transcription = cachedTranscription.transcription;
        mediaInfo = cachedTranscription.mediaInfo;
        setProcessingStage('Analyzing transcript');
      } else {
        setProcessingStage('Transcribing media');
        console.log('Transcribing media...');
        const transcribeUrl = `https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/transcribeMedia`;
        const transcribeResponse = await axios.post(transcribeUrl, { mediaUrl, jobId, uniqueFileName });
        transcription = transcribeResponse.data.transcription;
        mediaInfo = transcribeResponse.data.mediaInfo;
        setCachedTranscription({ transcription, mediaInfo });
      }

      setProcessingStage('Extracting highlights');
      console.log('Extracting highlights...');

      const extractUrl = `https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/extractHighlights`;
      const extractPayload = {
        transcription,
        duration: parseInt(selectedDuration, 10),
        mood: selectedMood,
        originalFileName,
        jobId,
        transcriptionFileName: `${uniqueFileName}-transcript.json`,
        mediaInfo
      };

      const extractResponse = await axios.post(extractUrl, extractPayload);

      if (extractResponse.status === 200) {
        const { highlightsUrl, moodWarning, durationWarning, finalDuration, highlightsFileName } = extractResponse.data;
        
        // Generate XML based on media type
        const xmlGenerationUrl = mediaType === 'video' 
            ? `https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createVideoXML`
            : `https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createAudioXML`;

        const xmlGenerationResponse = await axios.post(xmlGenerationUrl, {
          highlightsFileName,
          framerate: mediaType === 'video' ? 30 : 25,
          originalFileName
        });

        if (xmlGenerationResponse.status === 200) {
          const { xmlUrl } = xmlGenerationResponse.data;
          
          setDownloadUrls((prev) => ({
            ...prev,
            highlights: highlightsUrl,
            xml: xmlUrl,
          }));
          setIsGeneratingNewHighlights(false);
          
          let warningMessages = [];
          if (moodWarning) warningMessages.push(moodWarning);
          if (durationWarning) warningMessages.push(durationWarning);
          
          let message = `${mediaType.charAt(0).toUpperCase() + mediaType.slice(1)} XML generated successfully.`;
          if (warningMessages.length > 0) {
            message += ' Warnings:';
          }
          
          setShowMessage(true); // Show the message when processing is complete
          setMessage(
            <div>
              <p>{message}</p>
              {warningMessages.length > 0 && (
                <div className="warning-message">
                  <ul>
                    {warningMessages.map((warn, index) => (
                      <li key={index}>{warn}</li>
                    ))}
                  </ul>
                </div>
              )}
              <p>Final highlight duration: {finalDuration}s</p>
            </div>
          );
          
          // console.log('Highlights URL:', highlightsUrl);
          // console.log('XML URL:', xmlUrl);
        } else {
          throw new Error(xmlGenerationResponse.data.error || `Failed to generate ${mediaType} XML`);
        }
      } else {
        throw new Error(extractResponse.data.error || 'Failed to extract highlights');
      }
    } catch (error) {
      console.error('Processing error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      let errorMessage = 'Failed to process file. Please try again.';
      if (error.response && error.response.data) {
        if (error.response.data.error === 'NO_DIALOGUE') {
          errorMessage = error.response.data.message || 'No audio dialogue detected in the uploaded file. Please ensure the file contains spoken content.';
        } else if (error.response.data.error === 'DURATION_TOO_LONG') {
          errorMessage = error.response.data.message;
        } else if (error.response.data.error) {
          errorMessage = error.response.data.error;
        }
      } else if (error.message) {
        errorMessage = error.message;
      }
      setShowMessage(true); // Show the message when an error occurs
      setError(errorMessage);
      setMessage('');
      setIsGeneratingNewHighlights(false);
      setIsPolling(false);
      setJobId(null);
    } finally {
      setGeneratingHighlights(false);
      setProcessingStage('');
    }
  };

  const generateUniqueFileName = (originalName) => {
    const timestamp = Date.now();
    const fileNameWithoutExtension = originalName.split('.').slice(0, -1).join('.');
    const sanitizedFileName = kebabCase(fileNameWithoutExtension.toLowerCase());
    return `${sanitizedFileName}-${timestamp}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to access this page.</div>;
  }

  return (
    <div className="file-upload-container">
      <h1>Pocket Producer</h1>
      <div className="user-info">
        <UserNav />
      </div>

      {!user.isPremium && (
        <div className="upgrade-banner">
          <p>Upgrade to Premium for larger file uploads (up to 150MB) and more features!</p>
          <Link to="/upgrade" className="upgrade-button">Upgrade Now</Link>
        </div>
      )}

      <p className="overview">
        Upload a video or audio to receive AI-generated highlights.
      </p>

      <form className="form">
        <FileSelector file={file} onChange={handleChange} maxFreeSize={MAX_FREE_SIZE} maxPremiumSize={MAX_PREMIUM_SIZE} />
        {needsUpgrade && (
          <div className="upgrade-message">
            <p>This file size requires a paid account.</p>
            <Link to="/upgrade" className="upgrade-link">Upgrade Now</Link>
          </div>
        )}
        <HighlightOptions
          selectedMood={selectedMood}
          selectedDuration={selectedDuration}
          onMoodChange={handleMoodChange}
          onDurationChange={handleDurationChange}
        />
        <button
          type="button"
          className="process-button"
          onClick={handleUpload}
          disabled={!file || uploading || generatingHighlights || needsUpgrade}
        >
          Process
        </button>
        {error && (
          <div className="error-message">
            <p>{error}</p>
          </div>
        )}
        {showMessage && ( // Only render MessageDisplay when showMessage is true
          <MessageDisplay 
            message={message} 
            isProcessing={uploading || generatingHighlights} 
            className="message-display" 
          />
        )}
        {(uploading || generatingHighlights) && processingStage && (
          <ProgressDisplay 
            progress={uploading ? uploadProgress : processingProgress} 
            stage={processingStage} 
          />
        )}
        {downloadUrls.xml && !isGeneratingNewHighlights && (
          <DownloadLink 
            url={downloadUrls.xml} 
            filename={`${uniqueFileName.replace(/\.[^/.]+$/, "").replace(/\.(mp3|mp4|wav)/, "")}-highlights-${selectedDuration}s-${selectedMood.toLowerCase().replace(/\s+/g, '-')}.xml`}
            label="Download XML Highlights"
          />
        )}
      </form>
    </div>
  );
}

export default FileUpload;
