import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Login from './components/Login';
import Register from './components/Register';
import FileUpload from './components/FileUpload';
import RequestPasswordReset from './components/RequestPasswordReset/RequestPasswordReset';
import PrivateRoute from './components/PrivateRoute';
import Upgrade from './components/Upgrade';
import LoadingSpinner from './components/LoadingSpinner';
import './App.css';

function AppRoutes() {
  const { loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="App">
      <header className="App-header">{/* <h1>Audio File Upload</h1> */}</header>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/request-password-reset" element={<RequestPasswordReset />} />
        <Route path="/reset-password" element={<RequestPasswordReset />} /> 
        <Route path="/" element={<PrivateRoute> <FileUpload /> </PrivateRoute>} />
        <Route path="/upgrade" element={<PrivateRoute> <Upgrade /> </PrivateRoute>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
}

export default App;
